import React from "react";
const QuizGastrolPage = () => {
    return (
        <>
            <iframe className="w-[100%] h-screen"
                id="JotFormIFrame-231071280852450"
                title="Indo Default Mirxes Gastric Cancer Survey"
                // onload="window.parent.scrollTo(0,0)"
                allowtransparency="true"
                // allowfullscreen="true"
                allow="geolocation; microphone; camera"
                src="https://form.jotform.com/231071280852450"
                // frameborder="0"
            ></iframe>
        </>
    );
}
export default QuizGastrolPage;