
import './App.css';
import {Route, Routes } from "react-router";
import {Navigate} from 'react-router-dom';
// import LandingPageGasto from './pages/HomePage/HomePageGasto';
import { BrowserRouter } from 'react-router-dom';
// import AverageRiskGastoClear from './pages/ResultPageGastoClear/AverageRisk';
// import HighRiskGastoClear from './pages/ResultPageGastoClear/HighRisk';
// import LowRiskGastoClear from './pages/ResultPageGastoClear/LowRisk';
// import Testing from './pages/ResultPageGastoClear/Test';
import QuisionerPage from './pages/QuisionerPage/index';
import "@fontsource/metropolis";
import NotFoundPage from './pages/404/index';
import {ExternalAverage, ExternalLow, ExternalHigh, ExternalLandingPage} from './pages/ResultPageGastoClear/ExternalLink';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ExternalLandingPage />} />
          <Route path="/averagerisk-gastroclear" element={<ExternalAverage />} />
          <Route path="/highrisk-gastroclear" element={<ExternalHigh />} />
          <Route path="/lowrisk-gastroclear" element={<ExternalLow />} />
          <Route path='/quisioner-gastroclear' element={<QuisionerPage/>}/>
          {/* <Route path='/test' element={<Testing/>}/> */}
          <Route path='/404' element={<NotFoundPage/>}/>
          <Route path='*' element={<Navigate to="/404" />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
