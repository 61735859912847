export function ExternalAverage() {
    window.location.href = 'https://bumame.com/quiz/averagerisk-gastroclear/';
    return null;
  }
  export function ExternalLow() {
    window.location.href = 'https://bumame.com/quiz/lowrisk-gastroclear/';
    return null;
  }
  export function ExternalHigh() {
    window.location.href = 'https://bumame.com/quiz/highrisk-gastroclear/';
    return null;
  }

  export function ExternalLandingPage() {
    window.location.href = 'https://bumame.com/quiz/gastroclear/';
    return null;
  }
